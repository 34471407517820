.main{
    &__header{
       border-bottom: 1px solid rgba($primary, 0.1);
       h1{
          font-size: 28px;
          margin: 30px 0;
          letter-spacing: 0;
          line-height: 1;
       }
    }
    &__content{
       padding: 40px 0;
    }
    @media (max-width: $large){
       &__header{
         border: none;
         padding: 0;
         h1{
             font-size: 18px;
         }
       }
       &__content{
         padding: 20px 0;
       }
    }
 }