@import "../../../assets/scss/utilities/variables";
@import "../../../assets/scss/utilities/mixins";

.guide-nav {
  top: 80px;
  position: sticky;
  z-index: 10;

  &__btn {
    background: $details;
    cursor: pointer;
    @include border-radius(50%);
    @include flex(center, center);
    @include position(absolute, $width: 50px, $height: 50px, $top: 0);
    @include box-shadow(5px 5px 5px 0px rgba($black, 0.3));
    @include transition(all 0.5s ease);
    margin-top: -10px;
    z-index: 3;

    &--prev {
      left: 200px;
    }
    &--next {
      right: 50px;
    }
    &--disabled {
      display: none;
    }
    &:hover,
    &:focus {
      background: darken($details, 10%) !important;
    }
  }

  &.fixed{
    @include position(fixed, $top: 80px, $width: 100%);
    z-index: 4;

    .guide-nav__btn{
       top: 10px;
    }
 }
}
