@import "../../assets/scss/utilities/variables";
@import "../../assets/scss/utilities/mixins";

.user {
  padding: 20px 0;
  margin-left: 40px;
  position: relative;

  .user-profile {
    cursor: pointer;
    width: 150px;
    @include flex(center, space-between);

    &__name {
      p {
        font-family: $font-bold;
      }
    }

    .link {
        margin-bottom: -2px;
        text-decoration: none;
        &:hover,
        &:focus {
          color: $grey;
          @include transition(all 0.5s ease);
        }
      }
  }

  &:hover,
  &:focus {
    @media (min-width: $medium) {
      .nav-menu {
        display: block;
      }
    }
  }
}
