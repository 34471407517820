@import "mixins";

.i{
   background: white;
   display: inline-block;
   height: 14px;
   width: 14px;
   text-indent: -9999px;
   @include transition (all 0.5s ease);

   &--black{
      background: $black;
   }

   &--play{
      @include mask('../icons/app/i-play.svg');
   }
   &--add{
      @include mask('../icons/app/i-add.svg');
   }
   &--remove{
      @include mask('../icons/app/i-remove.svg');
   }
   &--ok{
      @include mask('../icons/app/i-ok.svg');
   }
   &--prev{
      @include mask('../icons/app/i-prev.svg');
   }
   &--next{
      @include mask('../icons/app/i-next.svg');
   }
   &--down{
      @include mask('../icons/app/i-down.svg');
   }
   &--player{
      @include mask('../icons/app/i-player.svg');
   }
   &--search{
      @include mask('../icons/app/i-search.svg');
   }
   &--close{
      @include mask('../icons/app/i-close.svg');
   }
   &--alert{
      @include mask('../icons/app/i-alert.svg');
   }
   &--guide{
      @include mask('../icons/app/i-guide.svg');
   }
   &--return{
      @include mask('../icons/app/i-return.svg');
   }
    &--info{
      @include mask('../icons/app/i-info.svg');
   }

   // Account
   &--list{
      @include mask('../icons/app/i-alert.svg');
   }
   &--susc{
      @include mask('../icons/app/i-alert.svg');
   }
   &--config{
      @include mask('../icons/app/i-alert.svg');
   }
   &--help{
      @include mask('../icons/app/i-info.svg');
   }
   &--logout{
      @include mask('../icons/app/i-logout.svg');
   }

   &--btn{
      background-color: rgba($grey, 0.5);
      background-size: 15px;
      @include border-radius(50%);
      @include position(relative, $width: 30px, $height: 30px);
      @media (max-width: $large){
         @include position(relative, $width: 22px, $height: 22px);
         background-size: 12px;
      }
   }

   &--green{
      background-color: $primary;
   }

   &--big{
      height: 30px;
      width: 30px;
   }

}
