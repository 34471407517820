@import "../../../assets/scss/utilities/variables";
@import "../../../assets/scss/utilities/mixins";

.header {
  background: $bg-header;
  @include position(fixed, $top: 0, $left: 0, $right: 0, $height: 80px);
  z-index: 10;

  &__container {
    height: 100%;
    align-items: center;
    display: flex;
  }

  &__item {
    &--center {
      width: 100%;
    }

    .operador {
      img {
        height: 60px;
        width: 60px;
        border-radius: 5%;
      }
    }

    .logo {
      cursor: pointer;
      img {
        width: 70px;
      }
    }
  }

  @media (max-width: $large) {
    background: transparent;
    padding: 20px 0;
    .logo {
      padding: 0;
      img {
        width: 130px;
      }
    }
  }
}
