@import "../../assets/scss/utilities/variables";
@import "../../assets/scss/utilities/mixins";

.slide{
    background: $secondary;
    min-height: 450px;
    overflow: hidden;
    position: relative;

    &__item{
      min-height: 450px;
   }
 
    .bullet-list{
      margin: 10px 0 35px 0;
    }
 
    &__wrapper{
       @include position(absolute, $top: 0, $left: 0, $width: 100%, $height: 100%);
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       z-index: 2;
    }
 
    &__header{
       height: 100px;
    }
 
    &__figure{
       margin-bottom: 30px;
    }
 
    &__container {
       display: flex;
       flex-direction: column;
       justify-content: start !important;
       height: inherit;
 
       img{
          margin-bottom: 40px;
       }
 
       ul, li{
          font-size: 18px;
       }
 
       h1{
          position: relative;
          small{
             @include position(absolute, $top: -40px, $left: 0);
          }
          
       }
       .banner_home_h1{
         margin-top:200px !important;
       }
 
       p{
          font-size: 18px;
          margin: 0 0 20px 0;
 
          &.text-small{
             font-size: 14px;
             margin: 10px 0;
          }
       }
 
       .btn--big{
          margin-top: 20px;
       }
    }
 
    &__footer{
       height: 200px;
       margin-bottom: 20px;
       padding-bottom: 20px;
    }
 
    &__background{
       &:after{
          content: '';
          background-image: linear-gradient(to bottom, rgba(12, 38, 46, 0) 0%, $secondary 87%);
          @include position(absolute, $top: 0, $left: 0, $width: 100%, $height: 100%);
          z-index: 1;
       }
       img{
          display: block;
          width: 100%;
       }
       &.not-deg{
          &:after{
             display: none;
          }
       }
    }
 
    &--border{
       border-bottom: 1px solid rgba($primary, 0.1);
    }
 
    .carousel{
       border: none;
       padding: 0;
    }
 
    &--big{
       max-height: 620px;
       .slide__container{
          justify-content: center;
       }

       .slide__wrapper{
         padding-left: 100px;
      }
    }

    .owl-carousel{
       .slide__background{
          img{
             width: 100% !important;
          }
       }
    }
 
 }
 