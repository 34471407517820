@import "../../../assets/scss/utilities/variables";
@import "../../../assets/scss/utilities/mixins";
.guide__programs{
 
    .programs{
       &__item{
          background: $bg-programs;
          border-bottom: 1px solid $secondary;
          border-right: 1px solid $secondary;
          padding: 15px 20px;
          position: relative;
          height: 90px;
          display: inline-block;
          cursor: pointer;
          a{
            font-family: "QanelasMedium", sans-serif; 
            text-decoration: none;
            color: black;
            &:hover, &:focus{
               color: $grey;
               @include transition (all 0.5s ease);
            }
         }

          &:first-child{
             margin: 0;
          }

          p{
             font-family: "QanelasMedium", sans-serif;
          }

          span{
             display: block;
             font-size: 14px;
             margin-bottom: 7px;
          }

          .i{
             @include position(absolute, $top: 10px, $right: 10px, $height: 20px, $width: 20px);
          }

          &--medium{
             flex: 0 0 320px;
             width: 320px;
          }

          &--big{
             flex: 0 0 480px;
             width: 480px;
          }

          &--now{
             background: white;
             p{
                color: $black;
             }
             span{
                color: $grey;
             }
          }

          &--fin{
            background: #364452;
            .i {
               display: none;
            }
         }
       }
    }
 }