@import "../../../assets/scss/utilities/variables";
@import "../../../assets/scss/utilities/mixins";

.guide__channel {
  background: $bg-body;
  flex: 0 0 200px;
  @include position(sticky, $left: 0, $width: 200px);
  z-index: 6;

  .channel {
    background: darken($bg-body, 8%);
    @include flex(center, center);
    height: 90px;
    padding: 10px;
    overflow: hidden;
    
    &:nth-child(2n) {
      background: darken($bg-body, 4%);
    }

    &--favorite {
      &::after {
        content: "";
        @include position(absolute, $top: 0, $left: 0, $width: 0, $height: 0);
        border-right: 10px solid transparent;
        border-top: 10px solid $primary;
        border-left: 10px solid $primary;
        border-bottom: 10px solid transparent;
      }
    }

    img {
      @include border-radius($radius);
      cursor: pointer;
      overflow: hidden;
      height: 100%;
      width: 70%;
    }
  }

  @media (max-width: $medium) {
    width: 100px;
    img {
      width: 60px;
    }
  }
}
