@import "../../../assets/scss/utilities/variables";

.footer{
    background: $bg-footer;
    margin-top: 40px;
    padding: 40px 0;
    position:relative;
    bottom:0px;
    width:100%;
    height:145px;
 
    &__container{
       display: flex;
       align-items: center;
    }
 
    &__item{
       &.flex--end{
          margin-left: 80px !important;
          width: 100%;
       }
    }
 
    .nav{
       align-items: flex-end;
       margin: 0;
       &__container{
          justify-content: flex-end;
       }
       &__item{
          height: auto;
          padding: 0;
          margin-left: 40px;
          a{
             font-family: $font-bold;
          }
       }
    }
 
    p{
       text-align: right;
    }
 
    @media (max-width: $large){
       display: none;
    }
 }
 