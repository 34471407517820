// Border Radus
@mixin border-radius($value){
	border-radius: 			$value;
	-moz-border-radius: 	$value;
	-ms-border-radius: 		$value;
	-webkit-border-radius: 	$value;	
}

// Box Shadow
@mixin box-shadow($value){
	box-shadow: 		$value;
	-moz-box-shadow: 	$value;
	-ms-box-shadows: 	$value;
	-webkit-box-shadow: $value;	
}

// Flex
@mixin flex($x, $y){
   display: flex;
   align-items: $x;
   justify-content: $y;
}

// Transition
@mixin transition($value){
	transition: 		$value;
    -moz-transition: 	$value;
    -ms-transition: 	$value;
    -webkit-transition: $value;    
}

// Transform
@mixin transform($value){
	transform: 			$value;
    -moz-transform: 	$value;
    -ms-transform: 		$value;
    -webkit-transform:  $value;    
}

// Transform
@mixin transform-origin($value){
	transform-origin: 			$value;
    -moz-transform-origin: 		$value;
    -ms-transform-origin: 		$value;
    -webkit-transform-origin: 	$value;    
}

// Transform
@mixin mask($value){
	mask: 			url($value) no-repeat 50% 50%;
    -moz-mask: 		url($value) no-repeat 50% 50%;
    -ms-mask: 		url($value) no-repeat 50% 50%;
    -webkit-mask: 	url($value) no-repeat 50% 50%;
}

// Position
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null, $width: null, $height: null) {
	position: 	$position;
	top: 		$top;
	right: 		$right;
	bottom: 	$bottom;
	left: 		$left;
	height: 	$height;
	width: 		$width;
}

@mixin horizontal-gradient($from, $to, $stop: 100%) {
   background-color: $from;
   background-image: -moz-linear-gradient(left, $from 0%, $to $stop);
   background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));
   background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);
   background-image: -o-linear-gradient(left, $from 0%, $to $stop);
   background: -ms-linear-gradient(left, $from 0%, $to $stop);
   background: linear-gradient(to right, $from 0%, $to $stop);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}
