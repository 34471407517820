// Class

.inline-list {
  display: flex;
}

.bullet-list {
  display: flex;
  li {
    &:not(:last-child):after {
      @include border-radius(50%);
      background: $primary;
      content: "";
      display: inline-block;
      margin: 0 10px;
      height: 5px;
      width: 5px;
    }
  }
}

.cat-type {
  border: 1px solid $grey;
  @include border-radius(1.5px);
  color: $grey;
  padding: 0 3px;
  font-family: $font-medium;
  font-size: 8px;

  &.cat--hd {
    background: $grey;
    color: darken($grey, 50%);
  }
}

// Background
.border-none {
  border: none !important;
}

.padding-none {
  padding: 0 !important;
}

.margin-40-0 {
  margin: 40px 0 !important;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.half {
  width: 50%;
}

.return {
  @include flex(center, left);
  margin: 30px 0;
  .i {
    height: 50px;
    width: 50px;
    margin-right: 20px;
  }
  p {
    font-family: $font-bold;
    font-size: 22px;
  }
}

// Hide
.hide-for-small {
  @media (max-width: $large) {
    display: none !important;
  }
}
.show-for-small {
  display: none;
  @media (max-width: $large) {
    display: block !important;
  }
}
