.flex{
   display: flex;
}

.flex--center-y{
   align-items: center;
}

.flex--end-y{
   align-items: center;
}

.flex--center-x{
   justify-content: center;
}

.flex--between-x{
   justify-content: space-between;
}

.flex--end-x{
   justify-content: flex-end;
}

.flex--center{
   margin: 0 auto;
}

.flex--end{
   margin-left: auto !important;
}
