.section{
    $section: &;
    padding: 40px 0;
    &__header{
       display: flex;
       margin-bottom: 20px;
       justify-content: space-between;
       p{
          font-size: 18px;
       }
    }
    &__content{
       display: flex;
       flex-wrap: wrap;
       margin: 0 -7px;
    }
    &__item{
       padding: 0 7px;
       margin-bottom: 40px;
       width: 100%;
    }
 
    img{
       height: auto;
       width: 100%;
    }
 
    &--gallery{
       #{ $section }__item{
          width: 25%;
       }
    }
 
    &--collection{
       margin-top: -180px;
       position: relative;
       z-index: 2;
 
       #{ $section }__content{
          justify-content: center;
       }
    }
 
    @media (min-width: $extra-large){
       &--gallery{
          flex-direction: column;
          #{ $section }__item{
             width: 20%;
          }
       }
    }
 
    @media (max-width: $large){
       &--gallery{
          flex-direction: column;
          #{ $section }__item{
             width: 50%;
          }
       }
    }
 
    @media (max-width: $medium){
       &--gallery{
          flex-direction: column;
          #{ $section }__item{
             width: 100%;
          }
       }
    }
 
 }
 