@import "../../assets/scss/utilities/variables";
@import "../../assets/scss/utilities/mixins";

.slide{
   .channel{
      overflow: hidden;
      margin-bottom: 40px;
      img{
         display: block;
         width: 300px;
         border-radius:6px;
      }
   }
    &--channel{
      min-height:700px;
    }
 }