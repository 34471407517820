.nav{
   margin-left: 80px;

   &__container{
      display: flex;
   }

   &__item{
      cursor: pointer;
      border-top: 6px solid transparent;
      height: 80px;
      position: relative;
      padding: 26px 0 32px;

      &--active{
         border-top: 6px solid $primary;
      }

      .i{
         margin-left: 10px;
         height: 8px;
         width: 12px;
      }

      span{
         display: block;
         height: 25px;
         @include transition (all 0.5s ease);
      }

      &:hover, &:focus{
         @media (min-width: $medium) {
            .nav-menu {
               display: block;
            }
         }
         span{
            font-family: $font-bold;
            .i{
               @include transform (rotate(180deg));
            }
         }
      }

   }
   @media (max-width: $large){
      display: none;
   }
}

.nav-menu{
   display: none;
   @include position(absolute, $right: 0, $top: 70px, $width: 250px);
   padding-top: 20px;

   &__container{
      @media (min-width: $medium) {
         border-top: 4px solid $primary;
         background: rgba($secondary, 0.9);
         padding: 30px 0 20px 0;
         @include transition (all 0.5s ease);
      }
   }

   &__item {
      cursor: pointer;
      padding: 10px 30px;
      a, span {
         display: flex;
         font-family: $font-regular;
         .i {
            display: none;
            margin-right: 8px;
            height: 20px;
            width: 22px;
         }
      }

      &--active{
         a{
            font-family: $font-bold;
         }
      }
   }
}

// Open menu
.i-nav{
   display: none;
   @media (max-width: $large){
      display: block;
   }
   cursor: pointer;
   @include position(fixed, $height: 24px, $width: 24px, $top: 35px, $right: 20px);
   @include transform(rotate(0deg));
   @include transition(.5s ease-in-out);
   z-index: 4;

   span{
      //background: $secondary;
      background: white;
      display: block;
      opacity: 1;
      @include position(absolute, $top: 0, $left: 0, $height: 3px, $width: 100%);
      @include transition(.25s ease-in-out);
      @include transform(rotate(0deg));

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3){
         @include transform-origin(left center);
      }

      &:nth-child(1){ top: 0px; }
      &:nth-child(2){ top: 8px; }
      &:nth-child(3){ top: 16px; }
   }

   &:hover, &:focus{
      span{
         background: $primary;
      }
   }

   &.active{
      span{
         &:nth-child(1){
            @include transform(rotate(45deg));
            @include position(absolute, $top: 0, $left: 0);
         }
         &:nth-child(2){
            width: 0%;
            opacity: 0;
         }
         &:nth-child(3){
            @include transform(rotate(-45deg));
            @include position(absolute, $top: 17px, $left: 0);
         }
      }
   }
}
