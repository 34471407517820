@import "../../assets/scss/utilities/variables";
@import "../../assets/scss/utilities/mixins";

.live {
  background-color: $bg-body;
  $live: &;
  position: relative;
  padding: 3px 15px;
  &__figure {
    border-radius: $radius;
    height: 120px;
    width: 220px;
    overflow: hidden;
    img {
      border-radius: $radius;
      display: block;
      @include transition(all 0.7s ease);
      height: 100% !important;
      width: 100% !important;
    }
  }
  &__figurecaption {
    margin-top: 15px;
    small {
      color: white;
      display: block;
      font-size: 14px;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 18px;
      & + span {
        font-family: $font-regular;
      }
    }
    p {
      font-family: $font-bold;
      color: white !important;
      font-size: 14px !important;
      text-transform: uppercase;
    }
    span {
      color: $grey;
      font-size: 14px;
    }
  }

  &:hover & {
    &__figure {
      img {
        transform: scale(1.05);
      }
    }
  }
}
