@import "../../assets/scss/utilities/variables";
@import "../../assets/scss/utilities/mixins";

.panel {
  $panel: &;
  border-radius: $radius;
  overflow: hidden;
  background: white;
  padding: 30px;
  width: 100%;

  &__header {
    height: 50px;
    margin-bottom: 20px;
    @include flex(center, space-between);

    .btn {
      margin: 0;
    }
  }

  &__content {
    h3 {
      color: $black;
      font-family: $font-bold;
      font-size: 22px;
    }
    p {
      color: $black;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .i--alert {
      cursor: pointer;
      @include position(
        absolute,
        $top: 20px,
        $right: 20px,
        $height: 22px,
        $width: 20px
      );
    }
    .btn {
      margin-top: 0;
    }
  }

  &__item {
    padding-bottom: 20px;
    margin: 20px;
    border-bottom: 1px solid rgba($grey, 0.2);
    h3 {
      display: flex;
      justify-content: space-between;
      span {
        font-family: $font-bold;
      }
    }
    p {
      font-family: $font-regular !important;
      font-size: 16px !important;
      &.type {
        margin-top: 20px;
      }
    }
    &:last-child {
      border: none;
      padding: 0;
    }
    @media (max-width: $large) {
      margin: 0 0 20px 0;
    }
  }

  &--dark {
    background: $third;
    padding: 35px 20px;
    min-width: 300px !important;

    h3 {
      color: white;
      font: {
        family: $font-bold;
        size: 20px;
      }
    }

    p {
      color: white;
      margin: 0;
    }
  }

  &--white {
    background: white;
    padding: 25px 30px;
    margin-bottom: 10px;

    p {
      font: {
        family: $font-bold;
        size: 20px;
      }
      margin: 0;
    }

    span {
      color: $black;
      font: {
        family: $font-regular;
        size: 20px;
      }
      margin: 0;
    }

    @media (max-width: $large) {
      padding: 15px 20px;
      p {
        font-size: 16px;
      }
      span {
        font-size: 14px;
      }
    }
  }

  &--live {
    @include box-shadow(10px 10px 30px 0 rgba(0, 0, 0, 0.5));
    background: transparent;
    padding: 0;
    #{ $panel }__content {
      display: flex;
    }
    #{ $panel }__figure {
      border-radius: 10px;
      width: 250px !important;
      img {
        width: 100% !important;
      }
    }
    #{ $panel }__info {
      background: white;
      padding: 20px;
      width: 300px;
    }
    p {
      color: $primary;
      font-size: 16px;
      font-family: $font-medium;
      margin: 0;
    }
    span {
      color: $black;
      font-size: 14px;
    }
    .progress {
      margin: 15px 0 10px 0;
    }
  }

  &--image {
    background: transparent;
    padding: 0;
    #{ $panel }__content {
      padding: 30px;
      @include position(absolute, $left: 0, $bottom: 0, $width: 60%);
      h3 {
        color: white;
        font-size: 30px;
        line-height: 1;
        margin-bottom: 5px;
      }
      p {
        color: white;
        font-size: 16px;
        letter-spacing: -0.18px;
        margin: 0;
      }
    }
    #{ $panel }__background {
      img {
        width: 100% !important;
      }
    }
  }

  &--small {
    @include border-radius(0);
    padding: 20px;
    p {
      color: $grey;
      font-size: 14px;
      margin: 0 !important;
    }
    h3 {
      font-family: $font-medium;
      size: 16px;
      margin-bottom: 10px;
    }
  }
}