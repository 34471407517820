body{
   background: $bg-body;
   padding-top: 80px;

   @media (max-width: $large){
      &.landing--mobile{
            padding: 0;
      }
      background-image: linear-gradient(to bottom, #07181e -8%, #1b4f60 70%);
      min-height: 100vh;
   }
}

*{
   border: none;
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

ul{
   margin: 0;
   list-style-type: none;
}

hr{
   background: $primary;
   display: block;
   height: 1px;
   margin: 20px 0;
}
