.bitmovinplayer-container {
  width: 100%;
  margin: 0 0 0 0 auto;
}
.bitmovinplayer-container video {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.bitmovinplayer-poster {
  width: 100%;
}

.bmpui-ui-watermark {
  display: none;
}

#player {
  margin: 10px auto;
}
