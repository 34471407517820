@import "../../assets/scss/utilities/variables";
@import "../../assets/scss/utilities/mixins";

.modal{
    background-color: rgba(black, 0.6);
    @include flex(center, center);
    @include position(fixed, $height: 100vh, $width: 100%, $top: 0, $left: 0);
    z-index: 10;
 
    &__content{
       background: white;
       position: relative;
       @include border-radius($radius);
       padding: 60px 80px;
       text-align: center;
       width: 500px;
 
       h2{
          color: $black;
          font-size: 26px;
          margin-bottom: 20px;
       }
 
       p{
          color: $black;
          font-size: 16px;
       }
 
       .btn{
          margin: 40px auto 0 auto;
          max-width: 250px;
       }
 
       .btn--link{
          font:{
             family: $font-bold;
             size: 16px;
          }
       }
 
       @media (max-width: $large) {
          padding: 30px;
          width: 80%;
       }
    }
 
    &__close{
       background: $secondary;
       cursor: pointer;
       display: block;
       @include flex(center, center);
       @include border-radius(50%);
       @include position(absolute, $top: -20px, $right: -20px, $height: 40px, $width: 40px);
 
       &:hover, &:focus{
          background: lighten($secondary, 5%);
       }
    }
 }