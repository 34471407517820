@import "../../assets/scss/utilities/variables";
@import "../../assets/scss/utilities/mixins";

.guide{
    margin: 50px 0 0 0;
    position: relative;
 
    &__content{
       position: relative;
       overflow: hidden;
       white-space: nowrap;
       overflow-y: scroll;
       display: flex;
       scrollbar-width: none;
       &::-webkit-scrollbar{
          display: none;
       }
    }
 
    &__item{
       background: $bg-schedule;
       border-bottom: 1px solid $secondary;
       border-right: 1px solid $secondary;
       height: 40px;
       padding: 10px 20px;
       width: 300px;
       flex: 0 0 300px;
       p{
          font-family: "QanelasBold", sans-serif;
       }
    }
    &__now{
       @include position(absolute, $top: -50px, $height: 430px, $left: 450px);
       z-index: 5;
       span{
          background: $primary;
          color: white;
          font-family: $font-bold;
          padding: 6px 12px;
          @include border-radius(3px);
       }
 
       &:after{
          background: $primary;
          content: '';
          @include position(absolute, $top: 20px, $height: 100%, $width: 2px, $left: 50%);
          z-index: 4;
       }
    }
 }
 
 
 