@import "../../assets/scss/utilities/variables";
@import "../../assets/scss/utilities/mixins";

.tabs {
  $tabs: &;
  h1 {
      font-size: 28px;
      margin-bottom: 60px;
    }
    
    &__items {
        /* background-color: red; */
    @include flex(center, bottom);
    border-bottom: 1px solid rgba($primary, 0.1);

    &--small {
      #{ $tabs }__item {
        margin-right: 40px;
        a {
          font: {
            family: $font-medium;
            size: 16px;
          }
          padding: 20px 0;
        }
        &--active {
          a {
            color: $primary;
            border: none;
          }
        }
      }
    }

    &--buttons {
      border: none;
      margin-bottom: 20px;
      #{ $tabs }__item {
        margin-right: 20px;
        a {
          background: $details;
          @include border-radius(20px);
          padding: 10px 30px;
          display: block;
          font: {
            family: $font-regular;
            size: 16px;
          }
          text-align: center;
          min-width: 140px;
          width: 100%;
        }
        &--active {
          a {
            background: $primary;
            border: none;
            color: white;
            font-family: $font-bold;
          }
        }
      }
    }
  }

  &__item {
    a {
      display: block;
      font-family: $font-regular;
      font-size: 20px;
      padding: 30px;
      text-align: center;
    }
    &--active {
      a {
        color: white;
        font-family: $font-medium;
        border-bottom: 2px solid $primary;
      }
    }
    @media (max-width: $large) {
      margin-right: 20px;
      a {
        font-size: 16px;
        padding: 10px;
      }
    }
  }

  &__content {
    padding: 30px 0;
    p {
      line-height: 1.63;
      margin-bottom: 20px;
      letter-spacing: -0.18px;

      &.detail {
        color: $grey;
        font-family: $font-regular;
        line-height: 1.5;
        letter-spacing: normal;
      }
    }
  }

  &--header {
    border: none;
    margin-left: 120px;
    #{ $tabs }__items {
      border: none;
      #{ $tabs }__item {
        margin-right: 20px;
      }
      a {
        padding: 30px 20px;
      }
    }
  }

  &--border {
    #{ $tabs }__item {
      &--active {
        a {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }

  &--auto-w {
    #{ $tabs }__item {
      a {
        padding: 30px 0;
      }
    }
  }
}
