@font-face {
   font-family: 'QanelasRegular';
   src: url('../fonts/qanelas-regular.woff2') format('woff2'), url('../fonts/qanelas-regular.woff') format('woff');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'QanelasBold';
   src: url('../fonts/qanelas-bold.woff2') format('woff2'), url('../fonts/qanelas-bold.woff') format('woff');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'QanelasMedium';
   src: url('../fonts/qanelas-semibold.woff2') format('woff2'), url('../fonts/qanelas-semibold.woff') format('woff');
   font-weight: normal;
   font-style: normal;
}
