@import "../../../assets/scss/utilities/variables";
@import "../../../assets/scss/utilities/mixins";

.btn{
    background: $primary;
    cursor: pointer;
    @include flex(center, center);
    @include border-radius($radius);
    @include transition (all 0.5s ease);
    padding: 15px 20px;
    font-family: $font-medium;
    font-size: 16px;

    @media (min-width: $large){
       max-width: 350px;
    }
 
    &:hover, &:focus{
       background: darken($primary, 10%);
       color: white;
    }
 
    &--disabled{
       opacity: 0.5;
    }
 
    &--big{
       font-size: 18px;
       padding: 15px 35px;
    }
 
    &--small{
       font-family: $font-regular;
       font-size: 14px;
       
       margin-right: 0;
       padding: 10px 30px;
 
       @media (max-width: $large){
          font-size: 12px;
          height: 30px;
          padding: 8px 15px;
       }
    }
 
    &--border{
       background: transparent;
       border: 1px solid rgba(255,255,255,0.5);
       padding: 15px 25px;
       &:hover, &:focus{
          background: transparent;
          border-color: $primary;
       }
    }
 
    &--grey,
    &--white{
       border: 1px solid rgba($black, 0.2);
       color: $black;
       font-family: $font-regular;
       font-size: 14px;
       
       padding: 8px 16px;
       @include border-radius(2px);
 
       &:hover, &:focus{
          border: 1px solid rgba($black, 0.7);
          color: $black;
       }
    }
 
    &--white{
       border: 1px solid rgba(white, 0.3);
       color: white;
    }
 
    &--black{
       background: $details;
       //margin-right: 0;
       &:hover, &:focus{
          background: darken($details, 10%);
       }
    }
 
    .i{
       margin-right: 10px;
       height: 20px;
       width: 18px;
    }
 
    &--circ{
       @include border-radius(50%);
       @include flex(center, center);
       padding: 0;
       width: 32px;
       height: 32px;
       .i{
          margin: 0;
          height: 14px;
          width: 14px;
       }
    }
 
    &--remove{
       background-color: rgba($grey, 0.5);
       &:hover, &:focus{
          background-color: rgba($grey, 0.7);
       }
    }
 }
 