@import "../../../assets/scss/utilities/variables";
@import "../../../assets/scss/utilities/mixins";

.epg-header {
  @include position(sticky, $top: 80px);
  z-index: 6;
  overflow: hidden;

  display: flex;
  &__overlay {
    top: 0px;
    left: 0px;
    position: absolute;
    background: $bg-body;
    width: 200px;
    height: 40px;
    z-index: 7;
  }

  &__schedule {
    white-space: nowrap;
    display: flex;
    margin-left: 200px;
    z-index: 5;
    @include position(sticky, $top: 80px);

    &.fixed {
      @include position(fixed, $top: 80px);
      z-index: 2;
    }
  }
}
