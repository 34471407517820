@import "../../assets/scss/utilities/variables";
@import "../../assets/scss/utilities/mixins";

.carousel{
    padding: 40px 0;
    &__header{
       @include flex(center, space-between);
       margin-bottom: 20px;
 
       p{
          font-size: 18px;
          margin: 5px 0 10px 0;
       }
 
       .link{
          color: $primary;
          font-family: $font-medium;
          .i{
             height: 10px;
             width: 10px;
          }
       }
    }
    &__content{
       display: flex;
       flex-wrap: wrap;
    }  
 }
 
 .owl-carousel{
    img{
       width: auto !important;
       //max-height: 174px;
    }
    .owl-item{
       img{
         display: block;
         width: 100%;
       }
    }
    .owl-nav{
       .owl-prev,
       .owl-next{
          background: $details !important;
          @include transition (all 0.5s ease);
          @include flex(center, center);
          @include box-shadow(5px 5px 5px 0px rgba($black,0.3));
          @include border-radius(50%);
          @include position(absolute, $left: 30px, $top: 32%, $width: 50px, $height: 50px);
          @include transform(translateY(-50%));
          &.disabled{
             display: none;
          }
          span{
             background: url('../../assets/scss/icons/app/i-prev.svg') no-repeat center center;
             display: block;
             height: 15px;
             width: 15px;
             text-indent: -9999px;
          }
          &:hover, &:focus{
             background: darken($details, 10%) !important;
          }
       }
       .owl-next{
          left: auto;
          right: 30px;
          span{
             background: url('../../assets/scss/icons/app/i-next.svg') no-repeat center center;
          }
       }
    }
    &.owl-movies{
       .owl-nav{
          .owl-prev,
          .owl-next{
             top: 40%;
          }
       }
    }
    .owl-dots{
       display: none;
    }
    &.owl-live{
       .owl-stage{
          @include flex(center, center);
       }
    }
 }
 