// Colors
$primary: #13afd7;
$secondary: #010221;
$third: #152646;
$details: #008eae;

// General color
$grey: #818181;
$black: #222;

// Background color
$bg-header: #080828;
$bg-body: #132140;
$bg-footer: #0f1939;

// Colores guide
$bg-channel:  #000021;
$bg-schedule: #0b4d6d;
$bg-programs: #123354;

// Alerts
$info: #099ab3;

// Fonts
$font-regular: 'QanelasRegular', sans-serif;
$font-medium:  'QanelasMedium', sans-serif;
$font-bold:    'QanelasBold', sans-serif;

// utilities
$spacing: 20px;
$radius: 6px;

// breakpoint
$extra-large: 1400px;
$large: 1023px;
$medium: 769px;
$small: 420px;
