*{
   color: white;
   font:{
      family: $font-regular;
      size: 16px;
   }
}

h1, h2, h3,
h1 span, h2 span, h3 span{
   color: white;
   font:{
      family: $font-bold;
      size: 62px;
      weight: normal;
   }
   small{
      display: block;
      font:{
         family: $font-medium;
         size: 18px
      }
      margin-bottom: 20px;
      letter-spacing: 1.29px;
      text-transform: uppercase;
   }
}

h1{
   line-height: 0.65;
   letter-spacing: -0.69px;
   margin-bottom: 20px;

   @media (max-width: $large){
      font-size: 52px;
   }
}

h2{
   font-size: 24px;
   @media (max-width: $large){
      font-size: 22px;
   }
}

h3{
   font-size: 24px;
}

a{
   text-decoration: none;
   &:hover, &:focus{
      color: $grey;
      @include transition (all 0.5s ease);
   }
}

.text-center{
   text-align: center;
}
