.container{
   padding: 0 60px;
   margin: 0 auto;
   width: 100%;
   .container{
      padding: 0;
   }

   @media (max-width: $large){
      padding: 0 20px;
   }

   &--small{
      padding: 0 170px;
   }
}

.g-row{
   display: flex;
   margin: 0 -10px;

   &__col{
      @media (min-width: $large) {
         padding: 0 10px;
         width: 50%;
      }
   }

   @media (max-width: $large){
      flex-direction: column;
      margin: 0;
   }
}
